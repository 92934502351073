export default {
  home: {
    link: '/',
    path: '/'
  },
  dashboard: {
    link: '/dashboard/',
    path: 'dashboard/',
    children: {
      candidateEvents: {
        link: '/dashboard/candidate-events/',
        path: '/candidate-events/',
        title: 'Candidate Events'
      },
      candidateEventsDetails: {
        // link: '/dashboard/candidate-events/',
        link: (id) => `/dashboard/candidate-events/${id}/`,
        path: '/candidate-events/:id/',
        title: 'Candidate events details'
      },
      referrers: {
        link: '/dashboard/referrers/',
        path: '/referrers/',
        title: 'Referrers'
      },
      consultants: {
        link: '/dashboard/company-users/',
        path: '/company-users/',
        title: 'Company Users'
      },
      analytics: {
        link: '/dashboard/analytics/',
        path: '/analytics/',
        title: 'Analytics'
      },
      referrals: {
        link: '/dashboard/referrals/',
        path: '/referrals/',
        title: 'Referrals'
      },
      jobReferrals: {
        link: '/dashboard/job-referrals/',
        path: '/job-referrals/',
        title: 'Job referrals'
      },
      appliedJobs: {
        link: '/dashboard/applied-jobs/',
        path: '/applied-jobs/',
        title: 'Applied Jobs'
      },
      jobAlerts: {
        link: '/dashboard/job-alerts/',
        path: '/job-alerts/',
        title: 'Job Alerts'
      },
      talentAlerts: {
        link: '/dashboard/talent-alerts/',
        path: '/talent-alerts/',
        title: 'Talent Alerts'
      },
      featured: {
        link: '/dashboard/featured/',
        path: '/featured/',
        title: 'Saved Jobs'
      },
      socialize: {
        link: '/dashboard/smartlinks/',
        path: '/smartlinks/',
        title: 'Smartlinks'
      },
      jobs: {
        link: '/dashboard/jobs/',
        path: '/jobs/',
        title: 'Jobs'
      },
      jobAds: {
        link: '/dashboard/job-ads/',
        path: '/job-ads/',
        title: 'Job Ads'
      },
      activities: {
        link: '/dashboard/activity-log/',
        path: '/activity-log/',
        title: 'Activity'
      },
      communications: {
        link: '/dashboard/communications-log/',
        path: '/communications-log/',
        title: 'Communications'
      },
      recruiters: {
        link: '/dashboard/companyusers/',
        path: '/companyusers/',
        title: 'Company Users'
      },
      users: {
        link: '/dashboard/users/',
        path: '/users/',
        title: 'General Users'
      },
      leaderboard: {
        link: '/dashboard/leaderboard/',
        path: '/leaderboard/',
        title: 'Leaderboard'
      },
      clients: {
        link: '/dashboard/clients/',
        path: '/clients/',
        title: 'Clients'
      },
      candidates: {
        link: '/dashboard/candidates-info/',
        path: '/candidates-info/',
        title: 'Candidates'
      },
      candidateAds: {
        link: '/dashboard/candidate-ads/',
        path: '/candidate-ads/',
        title: 'Candidate Ads'
      },
      candidateEnquiries: {
        link: '/dashboard/candidate-enquiries/',
        path: '/candidate-enquiries/',
        title: 'Candidate Enquiries'
      }
    }
  },
  testimonials: {
    feedback: '/testimonials/',
    requestRating: '/request-testimonial/',
    revisionTestimonial: '/testimonials/revision',
    children: {
      thank: '/testimonial/thanks/',
      addTestimonials: '/testimonial/add/',
      testimonialsShare: '/testimonial/review/',
      testimonialsReviewed: '/testimonial/reviewed/',
      testimonialsApprove: '/testimonials/approve/',
      testimonialsResponse: '/testimonials/response/',
      testimonialsExpired: '/testimonial/expired/'
    }
  },
  settings: {
    link: '/settings/',
    path: 'settings/',
    children: {
      editProfile: {
        link: '/settings/edit-profile/',
        path: '/edit-profile/',
        title: 'Account Profile'
      },
      agency: {
        link: '/settings/company/',
        path: '/company/',
        title: 'Company Settings'
      },
      accountSettings: {
        link: '/settings/account/',
        path: '/account/',
        title: 'Account Settings'
      },
      agencyBranding: {
        link: '/settings/company-branding/',
        path: '/company-branding/',
        title: 'Company Branding'
      },
      socialNetworks: {
        link: '/settings/social-networks/',
        path: '/social-networks/',
        title: 'Social Networks'
      },
      floatingLink: {
        link: '/settings/floating-link/',
        path: '/floating-link/',
        title: 'Floating Link'
      },
      EdmBuilder: {
        link: '/settings/edm-builder/',
        path: '/edm-builder/',
        title: 'EDM Builder Tool'
      },
      XmlFeeds: {
        link: '/settings/xml-feeds/',
        path: '/xml-feeds/',
        title: 'XML Feeds'
      }
    }
  },
  tools: {
    link: '/tools/',
    path: 'tools/',
    children: {
      EdmBuilder: {
        link: '/tools/edm-builder/',
        path: '/edm-builder/',
        title: 'EDM Builder Tool'
      },
      XmlFeeds: {
        link: '/tools/xml-feeds/',
        path: '/xml-feeds/',
        title: 'XML Feeds'
      },
      QuestionMapping: {
        link: '/tools/question-config/',
        path: '/question-config/',
        title: 'Question Config'
      },
      CreateJobAlert: {
        link: '/tools/create-job-alert/',
        path: '/create-job-alert/',
        title: 'Suggest Job Alert'
      },
      CreateTalentAlert: {
        link: '/tools/create-talent-alert/',
        path: '/create-talent-alert/',
        title: 'Suggest Talent Alert'
      },
      PitchMe: {
        link: '/tools/pitch-me/',
        path: '/pitch-me/',
        title: 'SmartPitch'
      }
    }
  },
  EdmBuilderPreview: {
    link: '/edm-builder/preview',
    path: '/edm-builder/preview',
    title: 'EDM Builder Tool'
  },
  PitchMePreview: {
    link: '/tools/pitch-me-preview/',
    path: '/pitch-me-preview/',
    title: 'Pitch Me Preview'
  },
  widgets: {
    link: '/widgets/',
    path: 'widgets/',
    children: {
      jobBoard: {
        link: '/widgets/job-board/',
        path: '/job-board/',
        title: 'Job Board'
      },
      talentBoard: {
        link: '/widgets/talent-board/',
        path: '/talent-board/',
        title: 'Talent Board'
      },
      searchBar: {
        link: '/widgets/search-bar/',
        path: '/search-bar',
        title: 'Search Bar'
      },
      latestJobs: {
        link: '/widgets/latest-jobs/',
        path: '/latest-jobs',
        title: 'Latest Jobs'
      },
      widgetAbout: {
        link: '/widgets/meet-the-team/',
        path: '/meet-the-team',
        title: 'Meet The Team'
      },
      floatingLink: {
        link: '/widgets/floating-link/',
        path: '/floating-link/',
        title: 'Floating Link'
      },
      yourRatings: {
        link: '/widgets/your-ratings/',
        path: '/your-ratings/',
        title: 'Your Ratings'
      },
      testimonials: {
        link: '/widgets/testimonial-carousels/',
        path: '/testimonial-carousels/',
        title: 'Testimonial Carousels'
      },
      testimonialGrids: {
        link: '/widgets/testimonial-grids/',
        path: '/testimonial-grids/',
        title: 'Testimonial Grids'
      },
      registerWithUs: {
        link: '/widgets/candidate-registration/',
        path: '/candidate-registration/',
        title: 'Candidate Registration'
      },
      referral: {
        link: '/widgets/referral/',
        path: '/referral/',
        title: 'Referral'
      },
      CategoryIDs: {
        link: '/widgets/category-ids/',
        path: '/category-ids/',
        title: 'Category IDs'
      }
    }
  },
  badges: {
    link: '/badges/',
    path: 'badges/',
    children: {
      yourProgress: {
        link: '/badges/your-progress/',
        path: '/your-progress/',
        title: 'Your Progress'
      },
      allBadges: {
        link: '/badges/all-badges/',
        path: '/all-badges/',
        title: 'All Badges'
      },
      agencyLeaderboard: {
        link: '/badges/leaderboard/',
        path: '/leaderboard/',
        title: 'Leaderboard'
      }
    }
  },
  toprec: {
    link: '/toprec/',
    path: 'toprec/',
    children: {
      ratings: {
        link: '/toprec/ratings/',
        path: '/ratings/',
        title: 'TopRec Ratings'
      },
      feedback: {
        link: '/toprec/feedback/',
        path: '/feedback/',
        title: 'TopRec Feedback'
      },
      settings: {
        link: '/toprec/settings/',
        path: '/settings/',
        title: 'TopRec Settings'
      },
      placements: {
        link: '/toprec/request-a-testimonial/',
        path: '/request-a-testimonial/',
        title: 'Request a Testimonial'
      }
    }
  },
  billing: {
    link: '/billing/',
    path: 'billing/',
    children: {
      budget: {
        link: '/billing/budget/',
        path: '/budget/',
        title: 'AI Budget'
      },
      cardSettings: {
        link: '/billing/billing-settings/',
        path: '/billing-settings/',
        title: 'Settings'
      },
      invoices: {
        link: '/billing/success-subscriptions/',
        path: '/success-subscriptions/',
        title: 'Success Subscriptions',
        children: {
          details: {
            link: (id) => `/billing/success-subscriptions/${id}/`,
            path: '/success-subscriptions/:id/',
            title: 'Invoice',
            hideActiveNavigation: true
          }
        }
      },
      payments: {
        link: '/billing/all-invoices/',
        path: '/all-invoices/',
        title: 'All Invoices',
        children: {
          details: {
            link: (id) => `/billing/all-invoices/${id}/`,
            path: '/all-invoices/:id/',
            title: 'Payments',
            hideActiveNavigation: true
          }
        }
      }
    }
  },
  reports: {
    link: '/reports/',
    path: 'reports/',
    children: {
      general: {
        link: '/reports/general/',
        path: '/general/',
        title: 'General'
      },
      candidateAquisition: {
        link: '/reports/candidate-acquisition/',
        path: '/candidate-acquisition/',
        title: 'Candidate Acquisition'
      },
      billingPredictor: {
        link: '/reports/billing-predictor/',
        path: '/billing-predictor/',
        title: 'Billing Predictor'
      }
    }
  },
  passwordReset: {
    link: '/profile/password-reset/',
    path: 'profile/password-reset/'
  },
  gamificationBadges: {
    link: '/gamification-badges/',
    path: 'gamification-badges/'
  },
  dataEraser: {
    link: '/data-eraser/',
    path: 'data-eraser/'
  },
  acceptInvite: {
    link: '/profile/accept-invite/',
    path: 'profile/accept-invite/'
  },
  acceptInviteReferrer: {
    link: '/profile/accept-invite-referrer/',
    path: 'profile/accept-invite-referrer/'
  },

  acceptInviteHiringManager: {
    link: '/profile/accept-invite-hiring-manager/',
    path: 'profile/accept-invite-hiring-manager/'
  },

  emailVerify: {
    link: '/profile/email-confirm/',
    path: 'profile/email-confirm/'
  },

  inviteConsultants: {
    link: '/invite-consultants/',
    path: 'invite-consultants/'
  },

  twitterCallback: {
    link: '/auth/twitter/',
    path: 'auth/twitter/'
  },

  contactUs: {
    link: '/contact-us/',
    path: 'contact-us/'
  },

  about: {
    link: '/about/',
    path: 'about/',
    name: 'about'
  },

  signIn: {
    link: '/sign-in',
    path: 'sign-in',
    name: 'sign-in'
  },

  privacy: {
    link: '/privacy-policy/',
    path: 'privacy-policy/',
    name: 'privacy-policy'
  },

  terms: {
    link: '/terms-of-use/',
    path: 'terms-of-use/',
    name: 'terms-of-use'
  },

  welcome: {
    link: '/company/:slug/',
    path: 'company/:slug/'
  },

  subscriptionsRemove: {
    link: '/subscriptions/remove/',
    path: 'subscriptions/remove/'
  },

  error: {
    link: '/404/',
    path: '404/'
  },

  createJobAlert: {
    link: '/create-job-alert/',
    path: 'create-job-alert/'
  },

  createTalentAlert: {
    link: '/create-talent-alert/',
    path: 'create-talent-alert/'
  },

  socialiseJob: {
    link: '/socialise-job/',
    path: 'socialise-job/'
  },

  pitchMeUrlPage: {
    link: '/pitch-me-url-page/',
    path: 'pitch-me-url-page/'
  },

  serverError: {
    link: '/500/',
    path: '500/'
  }
}
