/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  Checkbox as MUICheckbox,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
import {
  ExpandLess, ExpandMore
} from '@material-ui/icons'
import FilterCheck from '../../../shared/modals/filters/FilterCheck'

@observer
class JobFilterListItem extends React.Component {
  static propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    data: PropTypes.instanceOf(FilterCheck),
    count: PropTypes.number,
    className: PropTypes.string,
    checkboxClassName: PropTypes.string,
    showCounters: PropTypes.bool.isRequired,
    title: PropTypes.string,
    filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    saveDataInStore: PropTypes.bool
  }

  static defaultProps = {
    checkboxClassName: 'refari-filter-checkbox',
    className: '',
    title: '',
    filters: {},
    saveDataInStore: true
  }

  onChange = () => {
    const { onChangeFilter, data } = this.props
    data.toggle()
    onChangeFilter()
  }

  isOpen = () => {
    const { data, filters } = this.props
    const locationSearch = window.location.search.includes('location')
    if (filters.data && filters.data.length === 1 && !locationSearch) {
      return true
    }
    if (data.children && data.children.data) {
      return data.children.data.some((filter) => filter.selected)
    }
    return false
  }

  updateSelectedItem = (data) => {
    if (!this.props.saveDataInStore) {
      return null
    }
    const categoryList = sessionStorage.getItem('categoryList')
    const newData = JSON.parse(categoryList)
    const findIndex = newData.findIndex((el) => +el === +data.key)
    if (data.selected && findIndex !== -1) {
      newData.splice(findIndex, 1)

      if (data.children.data && data.children.data.length) {
        for (let i = 0; i < data.children.data.length; i++) {
          const chieldIndex = newData.findIndex(
            (el) => +el === +data.children.data[i].key
          )
          if (chieldIndex !== -1) {
            newData.splice(chieldIndex, 1)
          }
        }
      }
      if (data.parent) {
        const parentIndex = newData.findIndex((el) => +el === +data.parent.key)
        if (parentIndex !== -1) {
          newData.splice(parentIndex, 1)
        }
      }
    } else if (
      data.selected
      && data.children.data
      && data.children.data.length
    ) {
      for (let i = 0; i < data.children.data.length; i++) {
        const chieldIndex = newData.findIndex(
          (el) => +el === +data.children.data[i].key
        )
        if (chieldIndex !== -1) {
          newData.splice(chieldIndex, 1)
        }
      }
    } else {
      if (data.children.data && data.children.data.length) {
        for (let i = 0; i < data.children.data.length; i++) {
          if (!newData.includes(+data.children.data[i].key)) {
            newData.push(+data.children.data[i].key)
          }
        }
      }
      newData.push(+data.key)
    }
    sessionStorage.setItem('categoryList', JSON.stringify(newData))
  }

  addOrRemoveId = () => {
    const { data, title } = this.props
    if (title !== 'Category') {
      return null
    }
    if (!sessionStorage.getItem('categoryList')) {
      const list = []
      sessionStorage.setItem('categoryList', JSON.stringify(list))
      this.updateSelectedItem(data)
    } else {
      this.updateSelectedItem(data)
    }
  }

  addOrRemoveIdList = () => {
    this.addOrRemoveId()
    this.onChange()
  }

  handleClick = () => {
    this.props.data.toggleOpen()
  }

  render() {
    const {
      showCounters,
      count,
      data,
      className,
      checkboxClassName,
      title,
      filters,
      theme
    } = this.props
    return (
      <>
        <ListItem
          id={data.label}
          style={
            theme?.palette?.textColorCategories
              ? { color: theme?.palette?.textColorCategories }
              : {}
          }
          className={`refari-filter-item ${className}`}
          button
          onClick={this.addOrRemoveIdList}
        >
          <ListItemIcon>
            <MUICheckbox
              edge="start"
              color="primary"
              disableRipple
              className={checkboxClassName}
              checked={data.selected}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${data.label} ${
              showCounters && count ? `(${count})` : ''
            }`}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={this.handleClick}
            >
              {data.children.data && data.children.data.length && (
                <>{data.isOpenMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={data.isOpenMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.children
              && data.children.data
              && data.children.data
                .sort((a, b) => {
                  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
                  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
                  return 0
                })
                .map((filter) => {
                  const { count } = filter
                  return (
                    <JobFilterListItem
                      title={title}
                      filters={filters}
                      onClick={this.addOrRemoveId}
                      showCounters={this.props.showCounters}
                      count={count}
                      key={`${filter.key}_filter`}
                      data={filter}
                      onChangeFilter={this.props.onChangeFilter}
                      className="refari-nested"
                      checkboxClassName="refari-filter-checkbox-nested"
                    />
                  )
                })}
          </List>
        </Collapse>
      </>
    )
  }
}

export default JobFilterListItem
