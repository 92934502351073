/* eslint-disable @typescript-eslint/no-empty-function */
import { action, computed, observable, runInAction, toJS } from 'mobx'
import mergeImages from 'merge-images'
import WebFont from 'webfontloader'
import mime from 'mime-types'
import { toast } from 'react-toastify'
import { mapFormServerErrors } from '../utils/helpers'
// eslint-disable-next-line import/no-cycle
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import Messages from '../models/Messages'
import AuthSession from '../utils/AuthSession'
import Field from '../models/form/Field'
import PlayIcon from '../assets/images/play-icon.png'
import credentials from '../constants/credentials'
import alertMessages from '../constants/alertMessages'
// eslint-disable-next-line import/no-cycle
import RootStore from './RootStore'
import isEqual from 'lodash.isequal'
import SocializeStore from './SocializeStore'

export class PitchMeStore {
  fontHistory = {}
  @observable jobsList = []
  @observable expertiseList = []
  @observable testimonialsList = []
  @observable selectedJobList = []
  @observable selectedExpertiseList = []
  @observable selectedTestimonialList = []
  @observable search = ''
  @observable testimonialSearch = ''
  @observable editedInputType = ''
  @observable expertiseSearch = ''
  @observable consultantIn = ''
  @observable pitchMeCTALink = null
  @observable pitchMeCTATitle = null
  @observable pitchMeCTAWidth = 200
  @observable pitchMeCTAHeight = 36
  @observable pitchMeCTABorderRadius = 20
  @observable pitchMeCTAFontSize = 14
  @observable pitchMeCTAAlignment = { x: 0, y: 0 }

  @observable activeOnly = true
  @observable pitchMeLink = null
  @observable selectedTemplate = null
  @observable previewData = {}
  @observable isFetching = true
  @observable creationPost = false
  @observable isOpenModal = false
  @observable isEditedUpdated = false
  /**
   * @note this is used to make sure react doesn't go into infinite loop
   */
  @observable isComponentUpdated = true

  @observable.ref fonts = []
  @observable textColor = null
  @observable backgroundColor = null
  @observable lastAcceptedBackgroundColor = null
  @observable siteFont = ''
  @observable isBold = false
  @observable isItalic = false
  @observable templates = []

  @observable isOpenPitchMeCTAModal = false
  @observable isOpenTemplateModal = false
  @observable isOpenPitchMeEditModal = false
  @observable isOpenInputEditModal = false
  @observable warningPopup = false

  @observable selectedFontAlignment = 'left'
  @observable titleSelectedFontAlignment = 'left'
  @observable descriptionSelectedFontAlignment = 'left'
  @observable helpSelectedFontAlignment = 'left'
  @observable expertiseSelectedFontAlignment = 'left'
  @observable jobSelectedFontAlignment = 'left'
  @observable clientSelectedFontAlignment = 'left'
  @observable closingSelectedFontAlignment = 'left'
  @observable regardsSelectedFontAlignment = 'left'
  @observable testimonialsSelectedFontAlignment = 'left'

  @observable selectedFont = null
  @observable titleSelectedFont = null
  @observable descriptionSelectedFont = null
  @observable helpSelectedFont = null
  @observable expertiseSelectedFont = null
  @observable jobSelectedFont = null
  @observable clientSelectedFont = null
  @observable closingSelectedFont = null
  @observable regardsSelectedFont = null
  @observable testimonialsSelectedFont = null

  @observable titleSelectedFontSize = 36
  @observable descriptionSelectedFontSize = 15
  @observable helpSelectedFontSize = 23
  @observable expertiseSelectedFontSize = 23
  @observable jobSelectedFontSize = 23
  @observable clientSelectedFontSize = 23
  @observable closingSelectedFontSize = 18
  @observable regardsSelectedFontSize = 18
  @observable testimonialsSelectedFontSize = 23

  @observable titleTextColor =
    RootStore.agency && RootStore.agency.branding
      ? RootStore.agency.branding.primaryColor
      : null
  @observable descriptionTextColor = null
  @observable helpTextColor = null
  @observable expertiseTextColor = null
  @observable jobTextColor = null
  @observable clientTextColor = null
  @observable closingTextColor = null
  @observable regardsTextColor = null
  @observable testimonialsTextColor = null

  @observable titleIsBold = true
  @observable descriptionIsBold = false
  @observable helpIsBold = true
  @observable expertiseIsBold = true
  @observable jobIsBold = true
  @observable clientIsBold = true
  @observable closingIsBold = false
  @observable regardsIsBold = false
  @observable testimonialsIsBold = true

  @observable titleIsItalic = false
  @observable descriptionIsItalic = false
  @observable helpIsItalic = false
  @observable expertiseIsItalic = false
  @observable jobIsItalic = false
  @observable clientIsItalic = false
  @observable closingIsItalic = false
  @observable regardsIsItalic = false
  @observable testimonialsIsItalic = false

  @observable isPitchMeUpdated = false

  @observable.ref fontAlignment = [
    {
      label: 'Left',
      value: 'left'
    },
    {
      label: 'Right',
      value: 'right'
    },
    {
      label: 'Center',
      value: 'center'
    },
    {
      label: 'Justify',
      value: 'justify'
    }
  ]

  @observable.ref fontSizeArray = Array.from(
    { length: 90 },
    (_, i) => i + 10
  ).map((val) => ({
    label: val,
    value: val
  }))

  @observable image = new Field({
    name: 'image',
    value: null,
    file: true,
    fileData: {
      path: '',
      custom: false
    }
  })

  @observable initialPreviewState = {}
  @observable initialAdditionalPitchMeState = {}
  @observable additionalPitchMeState = {}

  @action
  initList = ({
    model,
    testimonialModel,
    url,
    testimonialURL,
    expertiseURL,
    search = '',
    consultantIn = '',
    activeOnly = true,
    initialFetching = true,
    modelDerivedData
  }) => {
    this.EntityModel = model
    this.TestimonialEntityModel = testimonialModel
    this.modelDerivedData = modelDerivedData
    this.url = url
    this.testimonialURL = testimonialURL
    this.expertiseURL = expertiseURL
    this.search = search
    this.consultantIn = consultantIn
    this.activeOnly = activeOnly
    initialFetching && this.fetchListData()
  }

  @action.bound
  togglePitchMeCTAModal() {
    this.isOpenPitchMeCTAModal = !this.isOpenPitchMeCTAModal
  }

  @action.bound
  toggleTemplateModal() {
    this.isOpenTemplateModal = !this.isOpenTemplateModal
  }

  @action.bound
  togglePitchMeEditModal() {
    this.isOpenPitchMeEditModal = !this.isOpenPitchMeEditModal
  }

  @action.bound
  toggleInputEditModal() {
    this.isOpenInputEditModal = !this.isOpenInputEditModal
  }

  @action.bound
  toggleWarningPopup() {
    this.warningPopup = !this.warningPopup
  }

  @action
  fetchTemplates = async () => {
    try {
      const response = await API.getData(ApiRoutes.publicPosts.pitchMeDrafts)
      if (response && response.data) {
        const templates = response.data.results.map(
          (item) => new Messages(item)
        )
        runInAction(() => {
          this.templates = templates
        })
      }
    } catch (e) {
      Logger.error(e, 'fetchTemplates')
    }
  }

  @action
  onSubmitTemplate = async (form) => {
    try {
      console.log('onSubmitTemplate')
      console.log(this.previewData.pitchMeTitle)
      if (this.previewData && this.previewData.pitchMeTitle) {
        this.creationPost = true
        if (
          (this.previewData.myVideoObj &&
            this.previewData.myVideoObj.image_url) ||
          (this.previewData.clientVideoObj &&
            this.previewData.clientVideoObj.image_url)
        ) {
          const imageSrc =
            this.previewData.myVideoObj.image_url ||
            this.previewData.clientVideoObj.image_url
          const imgMetaData = await this.getImageMeta(imageSrc)
          mergeImages(
            [
              {
                src: imageSrc,
                x: 0,
                y: 0
              },
              {
                src: PlayIcon,
                x:
                  imgMetaData && imgMetaData.width
                    ? imgMetaData.width / 2 - 50
                    : 270,
                y:
                  imgMetaData && imgMetaData.height
                    ? imgMetaData.height / 2 - 50
                    : 120
              }
            ],
            { crossOrigin: 'Anonymous' }
          )
            .then((b64) => {
              const imageBlob = this.dataURItoBlob(b64)
              this.saveTemplateData(form, imageBlob)
            })
            .catch((err) => {
              Logger.error(err)
              this.saveTemplateData(form)
            })
        } else {
          this.saveTemplateData(form)
        }
      }
    } catch (error) {
      Logger.error(error)
      console.log(error)
      this.saveTemplateData(form)
    }
  }

  @action
  saveTemplateData = async (form, imageBlob) => {
    form.pending(true)
    try {
      let imageID = ''
      if (imageBlob) {
        console.log('imageBlob invoked')
        let filename = 'image.png'
        if (imageBlob.type && mime.extension(imageBlob.type)) {
          filename = `image.${mime.extension(imageBlob.type)}`
        }

        const formData = new FormData()
        formData.append('image', imageBlob, filename)
        const response = await API.postData(
          ApiRoutes.publicPosts.imageCreate,
          formData
        )
        imageID = response.data.id
      }

      console.log('savetemppatedata-1')
      console.log('previewData')
      console.log(this.previewData)
      const data = {}
      data.name = form.fields.name.value
      data.metadata = this.previewData
      data.video_1 = this.previewData.pitchMeMyVideoURL || ''
      data.video_2 = this.previewData.pitchMeClientVideoURL || ''
      if (imageID) {
        data.image_obj = imageID
      }
      const response = await API.postData(
        ApiRoutes.publicPosts.pitchMeDrafts,
        data
      )
      toast.success(alertMessages.templateMessages)
      await this.fetchTemplates()

      runInAction(() => {
        this.isOpenTemplateModal = false
        this.creationPost = false
      })
      form.reset()
      form.pending(false)
      return response?.data?.id
    } catch (error) {
      Logger.error(error)
      console.log('saveTemplateData')
      console.log(error)
      form.pending(false)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  onUpdateTemplate = async (template) => {
    try {
      if (this.previewData && this.previewData.pitchMeTitle) {
        console.log('on update template', template)
        console.log(this.previewData.pitchMeDescription)
        console.log(template)

        this.creationPost = true
        if (
          (this.previewData.myVideoObj &&
            this.previewData.myVideoObj.image_url) ||
          (this.previewData.clientVideoObj &&
            this.previewData.clientVideoObj.image_url)
        ) {
          const imageSrc =
            this.previewData.myVideoObj.image_url ||
            this.previewData.clientVideoObj.image_url
          const imgMetaData = await this.getImageMeta(imageSrc)
          mergeImages(
            [
              {
                src: imageSrc,
                x: 0,
                y: 0
              },
              {
                src: PlayIcon,
                x:
                  imgMetaData && imgMetaData.width
                    ? imgMetaData.width / 2 - 50
                    : 270,
                y:
                  imgMetaData && imgMetaData.height
                    ? imgMetaData.height / 2 - 50
                    : 120
              }
            ],
            { crossOrigin: 'Anonymous' }
          )
            .then((b64) => {
              const imageBlob = this.dataURItoBlob(b64)
              this.updateTemplateData(template, imageBlob)
            })
            .catch((err) => {
              Logger.error(err)
              this.updateTemplateData(template)
            })
        } else {
          this.updateTemplateData(template)
        }
      } else {
        console.log('nothing works')
      }
    } catch (error) {
      Logger.error(error)
      this.updateTemplateData(template)
    }
  }

  @action
  updateTemplateData = async (template, imageBlob) => {
    try {
      let imageID = ''
      if (imageBlob) {
        let filename = 'image.png'
        if (imageBlob.type && mime.extension(imageBlob.type)) {
          filename = `image.${mime.extension(imageBlob.type)}`
        }
        const formData = new FormData()
        formData.append('image', imageBlob, filename)
        const response = await API.postData(
          ApiRoutes.publicPosts.imageCreate,
          formData
        )
        imageID = response.data.id
      }

      const data = {}
      data.metadata = this.previewData
      data.video_1 = this.previewData.pitchMeMyVideoURL || ''
      data.video_2 = this.previewData.pitchMeClientVideoURL || ''
      if (imageID) {
        data.image_obj = imageID
      }
      console.log('updateTemplateData', this.selectedTemplate)
      console.log(template?.id)

      const response = await API.patchData(
        ApiRoutes.publicPosts.pitchMeDraftsUpdate(template?.id),
        data
      )

      console.log('response', response)

      toast.success(alertMessages.updateTemplateMessages)
      await this.fetchTemplates()

      runInAction(() => {
        this.creationPost = false
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  renameTemplateName = async (id, name) => {
    try {
      await API.patchData(ApiRoutes.publicPosts.pitchMeDraftsUpdate(id), {
        name
      })
      toast.success(alertMessages.renameTemplateMessages)
      this.fetchTemplates()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  deleteTemplate = async (id) => {
    try {
      await API.deleteData(ApiRoutes.publicPosts.pitchMeDraftsUpdate(id))
      toast.success(alertMessages.deleteTemplateMessages)
      this.fetchTemplates()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  loadTemplate = async (id) => {
    try {
      if (id) {
        await this.loadFontList()
        const response = await API.getData(
          ApiRoutes.publicPosts.pitchMeDraftsUpdate(id)
        )
        if (response && response.data) {
          runInAction(() => {
            this.selectedTemplate = response.data
            this.previewData = response.data.metadata
            if (
              response.data.metadata &&
              response.data.metadata.pitchMeSelectedTestimonialList &&
              response.data.metadata.pitchMeSelectedTestimonialList.length > 0
            ) {
              this.selectedTestimonialList =
                response.data.metadata.pitchMeSelectedTestimonialList
            }
            if (
              response.data.metadata &&
              response.data.metadata.pitchMeSelectedJobList &&
              response.data.metadata.pitchMeSelectedJobList.length > 0
            ) {
              this.selectedJobList =
                response.data.metadata.pitchMeSelectedJobList
            }
            if (
              response.data.metadata &&
              response.data.metadata.pitchMeExpertise &&
              response.data.metadata.pitchMeExpertise.length > 0
            ) {
              this.selectedExpertiseList =
                response.data.metadata.pitchMeExpertise.map((item) => ({
                  id: item,
                  name: item
                }))
            }

            this.isComponentUpdated = false
            this.isPitchMeUpdated = false
          })
        }
      } else {
        runInAction(() => {
          this.previewData = null
          this.selectedTemplate = null
          this.isComponentUpdated = false
          this.isPitchMeUpdated = false
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  setPreviewData = (previewData) => {
    // Set font
    this.selectFont(
      previewData.selectedFont ? previewData.selectedFont.value : null
    )
    this.selectEditedFont(
      previewData.titleSelectedFont
        ? previewData.titleSelectedFont.value
        : null,
      'title'
    )
    this.selectEditedFont(
      previewData.descriptionSelectedFont
        ? previewData.descriptionSelectedFont.value
        : null,
      'description'
    )
    this.selectEditedFont(
      previewData.helpSelectedFont ? previewData.helpSelectedFont.value : null,
      'help'
    )
    this.selectEditedFont(
      previewData.expertiseSelectedFont
        ? previewData.expertiseSelectedFont.value
        : null,
      'expertise'
    )
    this.selectEditedFont(
      previewData.jobSelectedFont ? previewData.jobSelectedFont.value : null,
      'job'
    )
    this.selectEditedFont(
      previewData.clientSelectedFont
        ? previewData.clientSelectedFont.value
        : null,
      'client'
    )
    this.selectEditedFont(
      previewData.closingSelectedFont
        ? previewData.closingSelectedFont.value
        : null,
      'closing'
    )
    this.selectEditedFont(
      previewData.regardsSelectedFont
        ? previewData.regardsSelectedFont.value
        : null,
      'regards'
    )

    this.selectEditedFont(
      previewData.testimonialsSelectedFont
        ? previewData.testimonialsSelectedFont.value
        : null,
      'testimonials'
    )

    runInAction(() => {
      // Set Color
      this.textColor = previewData.textColor ? previewData.textColor : null
      this.backgroundColor = previewData.backgroundColor
      this.lastAcceptedBackgroundColor = previewData.backgroundColor
      this.titleTextColor = previewData.titleTextColor
        ? previewData.titleTextColor
        : previewData.textColor
      this.descriptionTextColor = previewData.descriptionTextColor
        ? previewData.descriptionTextColor
        : previewData.textColor
      this.helpTextColor = previewData.helpTextColor
        ? previewData.helpTextColor
        : previewData.textColor
      this.expertiseTextColor = previewData.expertiseTextColor
        ? previewData.expertiseTextColor
        : previewData.textColor
      this.jobTextColor = previewData.jobTextColor
        ? previewData.jobTextColor
        : previewData.textColor
      this.clientTextColor = previewData.clientTextColor
        ? previewData.clientTextColor
        : previewData.textColor
      this.closingTextColor = previewData.closingTextColor
        ? previewData.closingTextColor
        : previewData.textColor
      this.regardsTextColor = previewData.regardsTextColor
        ? previewData.regardsTextColor
        : previewData.textColor
      this.testimonialsTextColor = previewData.testimonialsTextColor
        ? previewData.testimonialsTextColor
        : previewData.textColor

      // Set Alignment
      this.selectedFontAlignment = previewData.selectedFontAlignment
      this.titleSelectedFontAlignment =
        previewData.titleSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.descriptionSelectedFontAlignment =
        previewData.descriptionSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.helpSelectedFontAlignment =
        previewData.helpSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.expertiseSelectedFontAlignment =
        previewData.expertiseSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.jobSelectedFontAlignment =
        previewData.jobSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.clientSelectedFontAlignment =
        previewData.clientSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.closingSelectedFontAlignment =
        previewData.closingSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.regardsSelectedFontAlignment =
        previewData.regardsSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.testimonialsSelectedFontAlignment =
        previewData.testimonialsSelectedFontAlignment ||
        previewData.selectedFontAlignment

      // Set font size
      this.titleSelectedFontSize = previewData.titleSelectedFontSize || 36
      this.descriptionSelectedFontSize =
        previewData.descriptionSelectedFontSize || 15
      this.helpSelectedFontSize = previewData.helpSelectedFontSize || 23
      this.expertiseSelectedFontSize =
        previewData.expertiseSelectedFontSize || 23
      this.jobSelectedFontSize = previewData.jobSelectedFontSize || 23
      this.clientSelectedFontSize = previewData.clientSelectedFontSize || 23
      this.closingSelectedFontSize = previewData.closingSelectedFontSize || 18
      this.regardsSelectedFontSize = previewData.regardsSelectedFontSize || 18
      this.testimonialsSelectedFontSize =
        previewData.testimonialsSelectedFontSize || 18

      // Set bold
      this.isBold = previewData.isBold
      this.titleIsBold =
        previewData.titleIsBold !== undefined
          ? previewData.titleIsBold
          : previewData.isBold
      this.descriptionIsBold =
        previewData.descriptionIsBold !== undefined
          ? previewData.descriptionIsBold
          : previewData.isBold
      this.helpIsBold =
        previewData.helpIsBold !== undefined
          ? previewData.helpIsBold
          : previewData.isBold
      this.expertiseIsBold =
        previewData.expertiseIsBold !== undefined
          ? previewData.expertiseIsBold
          : previewData.isBold
      this.jobIsBold =
        previewData.jobIsBold !== undefined
          ? previewData.jobIsBold
          : previewData.isBold
      this.clientIsBold =
        previewData.clientIsBold !== undefined
          ? previewData.clientIsBold
          : previewData.isBold
      this.closingIsBold =
        previewData.closingIsBold !== undefined
          ? previewData.closingIsBold
          : previewData.isBold
      this.regardsIsBold =
        previewData.regardsIsBold !== undefined
          ? previewData.regardsIsBold
          : previewData.isBold
      this.testimonialsIsBold =
        previewData.testimonialsIsBold !== undefined
          ? previewData.testimonialsIsBold
          : previewData.isBold

      // Set isItalic
      this.isItalic = previewData.isItalic
      this.titleIsItalic =
        previewData.titleIsItalic !== undefined
          ? previewData.titleIsItalic
          : previewData.isItalic
      this.descriptionIsItalic =
        previewData.descriptionIsItalic !== undefined
          ? previewData.descriptionIsItalic
          : previewData.isItalic
      this.helpIsItalic =
        previewData.helpIsItalic !== undefined
          ? previewData.helpIsItalic
          : previewData.isItalic
      this.expertiseIsItalic =
        previewData.expertiseIsItalic !== undefined
          ? previewData.expertiseIsItalic
          : previewData.isItalic
      this.jobIsItalic =
        previewData.jobIsItalic !== undefined
          ? previewData.jobIsItalic
          : previewData.isItalic
      this.clientIsItalic =
        previewData.clientIsItalic !== undefined
          ? previewData.clientIsItalic
          : previewData.isItalic
      this.closingIsItalic =
        previewData.closingIsItalic !== undefined
          ? previewData.closingIsItalic
          : previewData.isItalic
      this.regardsIsItalic =
        previewData.regardsIsItalic !== undefined
          ? previewData.regardsIsItalic
          : previewData.isItalic
      this.testimonialsIsItalic =
        previewData.testimonialsIsItalic !== undefined
          ? previewData.testimonialsIsItalic
          : previewData.isItalic

      // Set call to action button
      this.pitchMeCTALink = previewData.pitchMeCTALink || null
      this.pitchMeCTATitle = previewData.pitchMeCTATitle || null
      this.pitchMeCTAWidth = previewData.pitchMeCTAWidth || 200
      this.pitchMeCTAHeight = previewData.pitchMeCTAHeight || 36
      this.pitchMeCTABorderRadius = previewData.pitchMeCTABorderRadius || 20
      this.pitchMeCTAFontSize = previewData.pitchMeCTAFontSize || 14
      this.pitchMeCTAAlignment = previewData.pitchMeCTAAlignment || {
        x: 0,
        y: 0
      }
      this.setinitialPreviewState(previewData)
    })
  }

  setinitialPreviewState = (previewData) => {
    // Set font
    this.selectFont(
      previewData.selectedFont ? previewData.selectedFont.value : null
    )
    this.selectEditedFont(
      previewData.titleSelectedFont
        ? previewData.titleSelectedFont.value
        : null,
      'title'
    )
    this.selectEditedFont(
      previewData.descriptionSelectedFont
        ? previewData.descriptionSelectedFont.value
        : null,
      'description'
    )
    this.selectEditedFont(
      previewData.helpSelectedFont ? previewData.helpSelectedFont.value : null,
      'help'
    )
    this.selectEditedFont(
      previewData.expertiseSelectedFont
        ? previewData.expertiseSelectedFont.value
        : null,
      'expertise'
    )
    this.selectEditedFont(
      previewData.jobSelectedFont ? previewData.jobSelectedFont.value : null,
      'job'
    )
    this.selectEditedFont(
      previewData.clientSelectedFont
        ? previewData.clientSelectedFont.value
        : null,
      'client'
    )
    this.selectEditedFont(
      previewData.closingSelectedFont
        ? previewData.closingSelectedFont.value
        : null,
      'closing'
    )
    this.selectEditedFont(
      previewData.regardsSelectedFont
        ? previewData.regardsSelectedFont.value
        : null,
      'regards'
    )

    this.selectEditedFont(
      previewData.testimonialsSelectedFont
        ? previewData.testimonialsSelectedFont.value
        : null,
      'testimonials'
    )

    runInAction(() => {
      // Set Color
      this.initialPreviewState.textColor = previewData.textColor
        ? previewData.textColor
        : null
      this.initialPreviewState.backgroundColor = previewData.backgroundColor
      this.initialPreviewState.lastAcceptedBackgroundColor =
        previewData.backgroundColor
      this.initialPreviewState.titleTextColor = previewData.titleTextColor
        ? previewData.titleTextColor
        : previewData.textColor
      this.initialPreviewState.descriptionTextColor =
        previewData.descriptionTextColor
          ? previewData.descriptionTextColor
          : previewData.textColor
      this.initialPreviewState.helpTextColor = previewData.helpTextColor
        ? previewData.helpTextColor
        : previewData.textColor
      this.initialPreviewState.expertiseTextColor =
        previewData.expertiseTextColor
          ? previewData.expertiseTextColor
          : previewData.textColor
      this.initialPreviewState.jobTextColor = previewData.jobTextColor
        ? previewData.jobTextColor
        : previewData.textColor
      this.initialPreviewState.clientTextColor = previewData.clientTextColor
        ? previewData.clientTextColor
        : previewData.textColor
      this.initialPreviewState.closingTextColor = previewData.closingTextColor
        ? previewData.closingTextColor
        : previewData.textColor
      this.initialPreviewState.regardsTextColor = previewData.regardsTextColor
        ? previewData.regardsTextColor
        : previewData.textColor
      this.initialPreviewState.testimonialsTextColor =
        previewData.testimonialsTextColor
          ? previewData.testimonialsTextColor
          : previewData.textColor

      // Set Alignment
      this.initialPreviewState.selectedFontAlignment =
        previewData.selectedFontAlignment
      this.initialPreviewState.titleSelectedFontAlignment =
        previewData.titleSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.descriptionSelectedFontAlignment =
        previewData.descriptionSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.helpSelectedFontAlignment =
        previewData.helpSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.expertiseSelectedFontAlignment =
        previewData.expertiseSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.jobSelectedFontAlignment =
        previewData.jobSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.clientSelectedFontAlignment =
        previewData.clientSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.closingSelectedFontAlignment =
        previewData.closingSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.regardsSelectedFontAlignment =
        previewData.regardsSelectedFontAlignment ||
        previewData.selectedFontAlignment
      this.initialPreviewState.testimonialsSelectedFontAlignment =
        previewData.testimonialsSelectedFontAlignment ||
        previewData.selectedFontAlignment

      // Set font size
      this.initialPreviewState.titleSelectedFontSize =
        previewData.titleSelectedFontSize || 36
      this.initialPreviewState.descriptionSelectedFontSize =
        previewData.descriptionSelectedFontSize || 15
      this.initialPreviewState.helpSelectedFontSize =
        previewData.helpSelectedFontSize || 23
      this.initialPreviewState.expertiseSelectedFontSize =
        previewData.expertiseSelectedFontSize || 23
      this.initialPreviewState.jobSelectedFontSize =
        previewData.jobSelectedFontSize || 23
      this.initialPreviewState.clientSelectedFontSize =
        previewData.clientSelectedFontSize || 23
      this.initialPreviewState.closingSelectedFontSize =
        previewData.closingSelectedFontSize || 18
      this.initialPreviewState.regardsSelectedFontSize =
        previewData.regardsSelectedFontSize || 18
      this.initialPreviewState.testimonialsSelectedFontSize =
        previewData.testimonialsSelectedFontSize || 18

      // Set bold
      this.initialPreviewState.isBold = previewData.isBold
      this.initialPreviewState.titleIsBold =
        previewData.titleIsBold !== undefined
          ? previewData.titleIsBold
          : previewData.isBold
      this.initialPreviewState.descriptionIsBold =
        previewData.descriptionIsBold !== undefined
          ? previewData.descriptionIsBold
          : previewData.isBold
      this.initialPreviewState.helpIsBold =
        previewData.helpIsBold !== undefined
          ? previewData.helpIsBold
          : previewData.isBold
      this.initialPreviewState.expertiseIsBold =
        previewData.expertiseIsBold !== undefined
          ? previewData.expertiseIsBold
          : previewData.isBold
      this.initialPreviewState.jobIsBold =
        previewData.jobIsBold !== undefined
          ? previewData.jobIsBold
          : previewData.isBold
      this.initialPreviewState.clientIsBold =
        previewData.clientIsBold !== undefined
          ? previewData.clientIsBold
          : previewData.isBold
      this.initialPreviewState.closingIsBold =
        previewData.closingIsBold !== undefined
          ? previewData.closingIsBold
          : previewData.isBold
      this.initialPreviewState.regardsIsBold =
        previewData.regardsIsBold !== undefined
          ? previewData.regardsIsBold
          : previewData.isBold
      this.initialPreviewState.testimonialsIsBold =
        previewData.testimonialsIsBold !== undefined
          ? previewData.testimonialsIsBold
          : previewData.isBold

      // Set isItalic
      this.initialPreviewState.isItalic = previewData.isItalic
      this.initialPreviewState.titleIsItalic =
        previewData.titleIsItalic !== undefined
          ? previewData.titleIsItalic
          : previewData.isItalic
      this.initialPreviewState.descriptionIsItalic =
        previewData.descriptionIsItalic !== undefined
          ? previewData.descriptionIsItalic
          : previewData.isItalic
      this.initialPreviewState.helpIsItalic =
        previewData.helpIsItalic !== undefined
          ? previewData.helpIsItalic
          : previewData.isItalic
      this.initialPreviewState.expertiseIsItalic =
        previewData.expertiseIsItalic !== undefined
          ? previewData.expertiseIsItalic
          : previewData.isItalic
      this.initialPreviewState.jobIsItalic =
        previewData.jobIsItalic !== undefined
          ? previewData.jobIsItalic
          : previewData.isItalic
      this.initialPreviewState.clientIsItalic =
        previewData.clientIsItalic !== undefined
          ? previewData.clientIsItalic
          : previewData.isItalic
      this.initialPreviewState.closingIsItalic =
        previewData.closingIsItalic !== undefined
          ? previewData.closingIsItalic
          : previewData.isItalic
      this.initialPreviewState.regardsIsItalic =
        previewData.regardsIsItalic !== undefined
          ? previewData.regardsIsItalic
          : previewData.isItalic
      this.initialPreviewState.testimonialsIsItalic =
        previewData.testimonialsIsItalic !== undefined
          ? previewData.testimonialsIsItalic
          : previewData.isItalic

      // Set call to action button
      this.initialPreviewState.pitchMeCTALink =
        previewData.pitchMeCTALink || null
      this.initialPreviewState.pitchMeCTATitle =
        previewData.pitchMeCTATitle || null
      this.initialPreviewState.pitchMeCTAWidth =
        previewData.pitchMeCTAWidth || 200
      this.initialPreviewState.pitchMeCTAHeight =
        previewData.pitchMeCTAHeight || 36
      this.initialPreviewState.pitchMeCTABorderRadius =
        previewData.pitchMeCTABorderRadius || 20
      this.initialPreviewState.pitchMeCTAFontSize =
        previewData.pitchMeCTAFontSize || 14
      this.initialPreviewState.pitchMeCTAAlignment =
        previewData.pitchMeCTAAlignment || {
          x: 0,
          y: 0
        }
    })
  }

  loadFontList = async () => {
    if (this.fonts.length) return
    try {
      const response = await fetch(
        `${ApiRoutes.googlefonts}?key=${credentials.google.apiKey}&sort=popularity`
      )
      if (response && !response.error) {
        const data = await response.json()
        if (data) {
          runInAction(() => {
            const items = data.items.sort((a, b) =>
              a.family.localeCompare(b.family)
            )
            this.fonts = items.map((font) => ({
              label: font.family,
              value: font.family
            }))
          })
        }
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  selectFont = (name) => {
    if (name) {
      const selectedFont = this.fonts.filter((item) => item.value === name)[0]

      if (this.fontHistory[name]) {
        runInAction(() => {
          this.selectedFont = selectedFont
          this.initialPreviewState.selectedFont = selectedFont
        })
      } else {
        WebFont.load({
          google: {
            families: [name]
          },
          classes: false,
          fontloading: () => {},
          fontactive: () => {
            this.fontHistory[name] = true
            runInAction(() => {
              this.selectedFont = selectedFont
              this.initialPreviewState.selectedFont = selectedFont
            })
          },
          fontinactive: () => {}
        })
      }
    }
  }

  @action
  selectEditedFont = (name, type) => {
    if (name) {
      const selectedFont = this.fonts.filter((item) => item.value === name)[0]

      if (this.fontHistory[name]) {
        runInAction(() => {
          this[`${type}SelectedFont`] = selectedFont
          this.initialPreviewState[`${type}SelectedFont`] = selectedFont
        })
      } else {
        WebFont.load({
          google: {
            families: [name]
          },
          classes: false,
          fontloading: () => {},
          fontactive: () => {
            this.fontHistory[name] = true
            runInAction(() => {
              this[`${type}SelectedFont`] = selectedFont
              this.initialPreviewState[`${type}SelectedFont`] = selectedFont
            })
          },
          fontinactive: () => {}
        })
      }
    }
  }

  @action
  checkSiteFontFamily = () => {
    if (this.siteFont) return
    this.siteFont = 'Roboto'
  }

  @action
  fetchListData = async () => {
    try {
      AuthSession.setHeader()
      const response = await API.getData(this.url, this.composeFullParamsData)
      let jobsListArray = []
      if (response.data.results && response.data.results.length > 0) {
        jobsListArray = response.data.results.map(
          (item) => new this.EntityModel(item, this.modelDerivedData)
        )
      }

      const returnResult = jobsListArray.filter((el) => {
        const found = this.selectedJobList.find((item) => item.id === el.id)
        if (!found) {
          return el
        }
        return null
      })
      runInAction(() => {
        this.jobsList = returnResult.filter((el) => el)
        this.isFetching = false
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchTestimonialData = async () => {
    try {
      AuthSession.setHeader()
      const response = await API.getData(
        this.testimonialURL,
        this.composeTestimonialParamsData
      )
      let testimonialListArray = []
      if (response.data.results && response.data.results.length > 0) {
        testimonialListArray = response.data.results.map(
          (item) => new this.TestimonialEntityModel(item, this.modelDerivedData)
        )
      }

      const returnResult = testimonialListArray.filter((el) => {
        const found = this.selectedTestimonialList.find(
          (item) => item.testimonialId === el.testimonialId
        )
        if (!found) {
          return el
        }
        return null
      })
      runInAction(() => {
        this.testimonialsList = returnResult.filter((el) => el)
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchExpertiseData = async () => {
    try {
      AuthSession.setHeader()
      const response = await API.getData(this.expertiseURL)
      let specialitiesArray = []
      if (response.data.specialities && response.data.specialities.length > 0) {
        specialitiesArray = response.data.specialities
      }
      if (this.expertiseSearch) {
        const searchLower = this.expertiseSearch.toLowerCase()
        const filtered = specialitiesArray.filter((speciality) => {
          if (speciality.toLowerCase().includes(searchLower)) {
            return true
          }
          return false
        })
        specialitiesArray = filtered
      }

      const returnResult = specialitiesArray.filter((el) => {
        const found = this.selectedExpertiseList.find((item) => item === el)
        if (!found) {
          return el
        }
        return null
      })
      runInAction(() => {
        this.expertiseList = returnResult.filter((el) => el)
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  validateData = async (data) => {
    try {
      const response = await API.postData(
        ApiRoutes.publicPosts.pitchMeCheck,
        data
      )
      if (response.data && !response.data.codes) {
        runInAction(() => {
          this.previewData.myVideoObj = response.data.video_1 || {}
          this.previewData.clientVideoObj = response.data.video_2 || {}
        })
      } else {
        runInAction(() => {
          this.previewData.myVideoObj = {}
          this.previewData.clientVideoObj = {}
        })
      }
      runInAction(() => {
        this.creationPost = false
      })
    } catch (error) {
      Logger.error(error)
      runInAction(() => {
        this.previewData.myVideoObj = {}
        this.previewData.clientVideoObj = {}
        this.creationPost = false
      })
    }
  }

  @action
  onShareData = async () => {
    try {
      if (this.previewData && this.previewData.pitchMeTitle) {
        this.creationPost = true
        if (
          (this.previewData.myVideoObj &&
            this.previewData.myVideoObj.image_url) ||
          (this.previewData.clientVideoObj &&
            this.previewData.clientVideoObj.image_url)
        ) {
          const imageSrc =
            this.previewData.myVideoObj.image_url ||
            this.previewData.clientVideoObj.image_url
          const imgMetaData = await this.getImageMeta(imageSrc)
          mergeImages(
            [
              {
                src: imageSrc,
                x: 0,
                y: 0
              },
              {
                src: PlayIcon,
                x:
                  imgMetaData && imgMetaData.width
                    ? imgMetaData.width / 2 - 50
                    : 270,
                y:
                  imgMetaData && imgMetaData.height
                    ? imgMetaData.height / 2 - 50
                    : 120
              }
            ],
            { crossOrigin: 'Anonymous' }
          )
            .then((b64) => {
              const imageBlob = this.dataURItoBlob(b64)
              this.shareData(imageBlob)
            })
            .catch((err) => {
              Logger.error(err)
              this.shareData()
            })
        } else {
          this.shareData()
        }
      }
    } catch (error) {
      Logger.error(error)
      this.shareData()
    }
  }

  @action
  shareData = async (imageBlob) => {
    try {
      let imageID = ''
      if (imageBlob) {
        let filename = 'image.png'
        if (imageBlob.type && mime.extension(imageBlob.type)) {
          filename = `image.${mime.extension(imageBlob.type)}`
        }
        const formData = new FormData()
        formData.append('image', imageBlob, filename)
        const response = await API.postData(
          ApiRoutes.publicPosts.imageCreate,
          formData
        )
        imageID = response.data.id
      }

      const data = {}
      data.metadata = this.previewData
      data.video_1 = this.previewData.pitchMeMyVideoURL || ''
      data.video_2 = this.previewData.pitchMeClientVideoURL || ''
      if (imageID) {
        data.image_obj = imageID
      }
      const response = await API.postData(
        ApiRoutes.publicPosts.pitchMeCreate,
        data
      )
      runInAction(() => {
        this.pitchMeLink = response.data ? response.data.ssr_link : null
        this.creationPost = false
        this.isOpenModal = true
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length)
    // eslint-disable-next-line no-underscore-dangle
    const _ia = new Uint8Array(arrayBuffer)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i)
    }

    const dataView = new DataView(arrayBuffer)
    const blob = new Blob([dataView], { type: mimeString })
    return blob
  }

  @action
  getImageMeta = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = () => reject()
      img.src = url
    })

  @action
  getPitchMe = async (id) => {
    try {
      await this.loadFontList()
      AuthSession.setHeader()
      const response = await API.getData(ApiRoutes.publicPosts.pitchMeRead(id))
      if (response.data && response.data.metadata) {
        runInAction(() => {
          this.previewData = response.data.metadata
          const fontArray = [
            'title',
            'description',
            'help',
            'expertise',
            'job',
            'client',
            'closing',
            'regards',
            'testimonials'
          ]
          // eslint-disable-next-line array-callback-return
          fontArray.map((fontName) => {
            if (
              this.previewData[`${fontName}SelectedFont`] &&
              this.previewData[`${fontName}SelectedFont`].value
            ) {
              WebFont.load({
                google: {
                  families: [this.previewData[`${fontName}SelectedFont`].value]
                },
                classes: false,
                fontloading: () => {},
                fontactive: () => {
                  this.fontHistory[
                    this.previewData[`${fontName}SelectedFont`].value
                  ] = true
                },
                fontinactive: () => {}
              })
            }
          })
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  handleJobSearch = async (name = '') => {
    try {
      this.search = name
      await this.fetchListData()
      return this.jobsList
    } catch (e) {
      Logger.error(e, 'handleJobSearch')
    }
  }

  @action
  handleTestimonialSearch = async (name = '') => {
    try {
      this.testimonialSearch = name
      await this.fetchTestimonialData()
      return this.testimonialsList
    } catch (e) {
      Logger.error(e, 'handleTestimonialSearch')
    }
  }

  @action
  handleExpertiseSearch = async (name = '') => {
    try {
      this.expertiseSearch = name
      await this.fetchExpertiseData()
      return this.expertiseList
    } catch (e) {
      Logger.error(e, 'handleExpertiseSearch')
    }
  }

  @computed
  get composeFullParamsData() {
    const tempObj = {}
    if (this.search) {
      tempObj.search = this.search
    }
    if (this.consultantIn) {
      tempObj.consultant__in = this.consultantIn
    }
    tempObj.active_only = this.activeOnly
    return tempObj
  }

  @computed
  get composeTestimonialParamsData() {
    const tempObj = {}
    if (this.testimonialSearch) {
      tempObj.search = this.testimonialSearch
    }
    return tempObj
  }

  @action
  selectJob = (job) => {
    const exist = this.selectedJobList.find((el) => el.id === job.id)
    if (!exist) {
      this.selectedJobList = [...this.selectedJobList, job]
      const res = this.jobsList.filter((el) => el.id !== job.id)
      this.jobsList = res
    }
  }

  @action
  selectTestimonial = (testimonial) => {
    const exist = this.selectedTestimonialList.find(
      (el) => el.testimonialId === testimonial.testimonialId
    )
    if (!exist) {
      this.selectedTestimonialList = [
        ...this.selectedTestimonialList,
        testimonial
      ]
      const res = this.testimonialsList.filter(
        (el) => el.testimonialId !== testimonial.testimonialId
      )
      this.testimonialsList = res
    }
  }

  @action
  selectExpertise = (expertise) => {
    const exist = this.selectedExpertiseList.find(
      (el) => el.id === expertise.id
    )
    if (!exist) {
      this.selectedExpertiseList = [...this.selectedExpertiseList, expertise]
      const res = this.expertiseList.filter((el) => el.id !== expertise.id)
      this.expertiseList = res
    }
  }

  @action
  removeJob = (job) => {
    const res = this.selectedJobList.filter((el) => el.id !== job.id)
    this.selectedJobList = res
    this.jobsList = [...this.jobsList, job]
  }

  @action
  removeTestimonial = (testimonial) => {
    const res = this.selectedTestimonialList.filter(
      (el) => el.testimonialId !== testimonial.testimonialId
    )
    this.selectedTestimonialList = res
    this.testimonialsList = [...this.testimonialsList, testimonial]
  }

  @action
  syncStateAfterUpdate = () => {
    console.group('additional state')
    console.log(toJS(this.initialAdditionalPitchMeState))
    console.log(toJS(this.additionalPitchMeState))
    console.log(this.isPitchMeEnableToggleUpdated())
    console.groupEnd()
    const defaultOrInitialPreviewState = toJS(this.initialPreviewState)

    if (!this.selectedTemplate) {
      this.isPitchMeUpdated = !(
        isEqual(defaultOrInitialPreviewState, {}) &&
        isEqual(
          toJS(this.initialAdditionalPitchMeState),
          toJS(this.additionalPitchMeState)
        ) &&
        this.isPitchMeEnableToggleUpdated()
      )
      return
    }
    if (typeof defaultOrInitialPreviewState === 'object') {
      const currentPreviewState = Object.keys(
        defaultOrInitialPreviewState
      ).reduce((accumulator, key) => {
        if (typeof this[key] === 'object') {
          accumulator[key] = toJS(this[key])
          return accumulator
        }

        accumulator[key] = this[key]
        return accumulator
      }, {})

      this.isPitchMeUpdated = !(
        isEqual(currentPreviewState, defaultOrInitialPreviewState) &&
        isEqual(
          toJS(this.initialAdditionalPitchMeState),
          toJS(this.additionalPitchMeState)
        ) &&
        this.isPitchMeEnableToggleUpdated()
      )
    }
  }

  isPitchMeEnableToggleUpdated = () => {
    return (
      SocializeStore.enablePitchMeProfileSection ===
        this.previewData.showProfile &&
      SocializeStore.enablePitchMeHelpSection ===
        this.previewData.enablePitchMeHelpSection &&
      SocializeStore.enablePitchMeExpertiseSection ===
        this.previewData.enablePitchMeExpertiseSection &&
      SocializeStore.enablePitchMeJobSection ===
        this.previewData.enablePitchMeJobSection &&
      SocializeStore.enablePitchMeClientSection ===
        this.previewData.enablePitchMeClientSection
    )
  }

  @action
  updateAdditionalStates = (key, value) => {
    this.additionalPitchMeState[key] = value
    console.log(this.additionalPitchMeState)
    // this.syncStateAfterUpdate()
  }

  @action
  updateInitialAdditionalPitchMeState = (key, value) => {
    this.initialAdditionalPitchMeState[key] = value
    this.additionalPitchMeState[key] = value
  }

  @action
  removeExpertise = (expertise) => {
    const res = this.selectedExpertiseList.filter(
      (el) => el.id !== expertise.id
    )
    this.selectedExpertiseList = res
    this.expertiseList = [...this.expertiseList, expertise]
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  resetStoreData() {
    this.selectedJobList = []
    this.selectedExpertiseList = []
    this.selectedTestimonialList = []
    this.search = ''
    this.testimonialSearch = ''
    this.editedInputType = ''
    this.expertiseSearch = ''
    this.consultantIn = ''
    this.pitchMeCTALink = null
    this.pitchMeCTATitle = null
    this.pitchMeCTAWidth = 200
    this.pitchMeCTAHeight = 36
    this.pitchMeCTABorderRadius = 20
    this.pitchMeCTAFontSize = 14
    this.pitchMeCTAAlignment = { x: 0, y: 0 }

    this.pitchMeLink = null
    this.selectedTemplate = null
    this.previewData = {}
    this.creationPost = false
    this.isOpenModal = false
    /**
     * @note look into this
     */
    this.isEditedUpdated = false

    // @observable.ref fonts = []

    /**
     * @note it is set as a custom color for default value in PitchMePage
     */
    this.textColor = '#000000'
    this.backgroundColor = null
    this.lastAcceptedBackgroundColor = null
    // @observable siteFont = ''
    this.isBold = false
    this.isItalic = false

    this.isOpenPitchMeCTAModal = false
    this.isOpenTemplateModal = false
    this.isOpenPitchMeEditModal = false
    this.isOpenInputEditModal = false
    this.warningPopup = false

    this.selectedFontAlignment = 'left'
    this.titleSelectedFontAlignment = 'left'
    this.descriptionSelectedFontAlignment = 'left'
    this.helpSelectedFontAlignment = 'left'
    this.expertiseSelectedFontAlignment = 'left'
    this.jobSelectedFontAlignment = 'left'
    this.clientSelectedFontAlignment = 'left'
    this.closingSelectedFontAlignment = 'left'
    this.regardsSelectedFontAlignment = 'left'
    this.testimonialsSelectedFontAlignment = 'left'

    this.selectedFont = null
    this.titleSelectedFont = null
    this.descriptionSelectedFont = null
    this.helpSelectedFont = null
    this.expertiseSelectedFont = null
    this.jobSelectedFont = null
    this.clientSelectedFont = null
    this.closingSelectedFont = null
    this.regardsSelectedFont = null
    this.testimonialsSelectedFont = null

    this.titleSelectedFontSize = 36
    this.descriptionSelectedFontSize = 15
    this.helpSelectedFontSize = 23
    this.expertiseSelectedFontSize = 23
    this.jobSelectedFontSize = 23
    this.clientSelectedFontSize = 23
    this.closingSelectedFontSize = 18
    this.regardsSelectedFontSize = 18
    this.testimonialsSelectedFontSize = 23

    this.titleTextColor =
      RootStore.agency && RootStore.agency.branding
        ? RootStore.agency.branding.primaryColor
        : null
    this.descriptionTextColor = null
    this.helpTextColor = null
    this.expertiseTextColor = null
    this.jobTextColor = null
    this.clientTextColor = null
    this.closingTextColor = null
    this.regardsTextColor = null
    this.testimonialsTextColor = null

    this.titleIsBold = true
    this.descriptionIsBold = false
    this.helpIsBold = true
    this.expertiseIsBold = true
    this.jobIsBold = true
    this.clientIsBold = true
    this.closingIsBold = false
    this.regardsIsBold = false
    this.testimonialsIsBold = true

    this.titleIsItalic = false
    this.descriptionIsItalic = false
    this.helpIsItalic = false
    this.expertiseIsItalic = false
    this.jobIsItalic = false
    this.clientIsItalic = false
    this.closingIsItalic = false
    this.regardsIsItalic = false
    this.testimonialsIsItalic = false

    this.fontAlignment = [
      {
        label: 'Left',
        value: 'left'
      },
      {
        label: 'Right',
        value: 'right'
      },
      {
        label: 'Center',
        value: 'center'
      },
      {
        label: 'Justify',
        value: 'justify'
      }
    ]

    /**
     * @note reset the initialPreviewData
     */

    this.initialPreviewState = {}
    this.isPitchMeUpdated = false
    this.additionalPitchMeState = {}
    this.initialAdditionalPitchMeState = {}

    this.image = new Field({
      name: 'image',
      value: null,
      file: true,
      fileData: {
        path: '',
        custom: false
      }
    })
  }
}

export default new PitchMeStore()
