/* eslint-disable max-len */
import { observable, action, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import EmlModal from '../models/Exm'
import alertMessages from '../constants/alertMessages'

export class XmlStore {
  @observable xmlList = {}

  @action
  fetchXmlList = async (page = 1) => {
    try {
      const res = await API.getData(ApiRoutes.dashboard.xml.list(page))
      const { data } = res
      data.results = res.data.results.map((el) => new EmlModal(el))
      runInAction(() => (this.xmlList = data))
    } catch (err) {
      Logger.error(err)
    }
  }

  @action
  addNewXmlList = async (data) => {
    try {
      await API.postData(ApiRoutes.dashboard.xml.path, data)
      toast.success(alertMessages.addXml)
      this.fetchXmlList()
    } catch (err) {
      Logger.error(err, 'addNewXmlList')
      if (err.data.non_field_errors) {
        toast.error(err.data.non_field_errors)
      }
    }
  }

  @action
  removeXmlList = async (id) => {
    try {
      await API.deleteData(ApiRoutes.dashboard.xml.remove(id))
      toast.success(alertMessages.removeXml)
      this.fetchXmlList()
    } catch (err) {
      Logger.error(err, 'removeXmlList')
    }
  }

  // @action
  // updateXmlList = async (id, showDetailedLocation) => {
  //   try {
  //     await API.putData(ApiRoutes.dashboard.xml.update(id), { show_detailed_location: !showDetailedLocation })
  //     toast.success(alertMessages.updateXml)
  //     this.fetchXmlList()
  //   } catch (err) {
  //     Logger.error(err, 'updateNewXmlList')
  //   }
  // }
}

export default new XmlStore()
